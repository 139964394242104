import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const SpatialAnalysisPage = () => {
  return (
    <Layout
      meta={{
        description:
          'A powerful suite of spatial analysis tools that anyone can use. No prior GIS knowledge required.',
        title: 'Spatial Analysis | Atlas',
        type: 'website'
      }}
      title={'Spatial Analysis | Atlas'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'50+ spatial analysis tools'}
            description={
              'A powerful suite of spatial analysis tools that anyone can use. No prior GIS knowledge required.'
            }
            video="https://cdn.atlas.co/landing-pages/spatial-analysis-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>It's as easy as using a calculator</h2>
            <p>
              GIS analysis used to be for experts only. With Atlas, anyone can
              perform advanced analyses without prior knowledge.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/home-buffer.mp4'}
            icon={{ src: '/icons/buffer-icon.svg', color: '#FFF4E7' }}
          >
            <h3>Core GIS operations</h3>
            <p>Buffer, clip, subtract, intersect, dissolve, and much more.</p>
            <p>
              Perform both vector and raster operations with ease. Our tools are
              designed to be intuitive and easy to use, so you can focus on your
              analysis, not the software.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/home-travel-time.mp4'}
            reverse
            icon={{ src: '/icons/lightning.svg', color: '#CDE4E1' }}
          >
            <h3>Advanced spatial analysis</h3>
            <p>
              Conduct proximity searches, overlay analysis, and data aggregation
              to reveal hidden patterns and relationships.
            </p>
            <p>
              Easily perform routing, conversions, and density analyses to
              support your decision-making processes.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={
              'https://cdn.atlas.co/landing-pages/shade-analysis-video.mp4'
            }
            icon={{
              src: '/icons/wind-energy-analysis.svg',
              color: '#C2E5FF'
            }}
          >
            <h3>Domain-specific workflows</h3>
            <p>Unlock deeper insights with domain-specific spatial analysis.</p>
            <p>
              Estimate wind power production, calculate aspect-slope or
              visualize how many hours of sunlight on specific dates.
            </p>
            <p>
              Whether you’re in real estate, urban planning, or environmental
              science, our tools are designed to help you make better decisions
              faster.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>
      </main>
    </Layout>
  )
}

export default SpatialAnalysisPage
